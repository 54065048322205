import { reactLocalStorage } from 'reactjs-localstorage';
import Resizer from "react-image-file-resizer";
import config from './config';
import moment from 'moment';
// import moment from 'moment-timezone';
import { utils, writeFile } from 'xlsx';

var DURATION_IN_SECONDS = {
  epochs: ['year', 'month', 'day', 'hour', 'minute'],
  year: 31536000,
  month: 2592000,
  day: 86400,
  hour: 3600,
  minute: 60,
};

const getBase64FromUrl = (url) => {
  var request = require('request').defaults({ encoding: null });
  let data = "";
  request.header = new Headers({
    "Access-Control-Allow-Origin": "*",
    "mode": "no-cors"
  });
  request.get(url, function (error, response, body) {
    if (!error && response.statusCode === 200) {
      data = Buffer.from(body).toString('base64');
      return data;
    }
  });
}
const imageUrlToBase64 = async (url) => {
  const data = await fetch(url, { 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'crosorigin': 'anonymous' });
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};
const common = {
  getBase64FromUrl,
  imageUrlToBase64,
  handleExport(filename, koloms, data) {
    const headings = koloms;
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, filename + '.xlsx');
  },

  productionMode() {
    var oldConsoleLog = null;
    if (config.production_mode) {
      oldConsoleLog = console.log;
      window['console']['log'] = function () { };
    } else {
      if (oldConsoleLog === null)
        return;

      window['console']['log'] = oldConsoleLog;
    }
  },
  isObjectEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  },
  textRegex(val) {
    const re = /^[A-Za-z ]+$/;
    return re.test(val);
  },
  nikRegex(nik) {
    const re = /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/;
    return re.test(nik);
  },
  emailRegex(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  phoneRegex(phone) {
    const re = /^08[0-9]{8,}$/;
    return re.test(phone);
  },
  numberRegex(phone) {
    const re = /^[0-9]$/;
    return re.test(phone);
  },
  passwordRegex(pass) {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return re.test(pass);
  },
  setFullLoading(param) {
    if (!param) {
      return false;
    } else {
      return true;
    }
  },
  countDays(date1, date2) {

    if (date1 === date2) {
      return '1 Day';
    } else {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(date1);
      const secondDate = new Date(date2);
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      if (diffDays > 1) {
        return diffDays + ' Days';
      } else {
        return diffDays + ' Day';
      }
    }
  },
  countDaysAngka(date1, date2) {

    if (date1 === date2) {
      return '1 Day';
    } else {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(date1);
      const secondDate = new Date(date2);
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      if (diffDays > 1) {
        return diffDays;
      } else {
        return diffDays;
      }
    }

  },
  countHours(date, time1, time2) {
    let times1 = date + ' ' + time1;
    let times2 = date + ' ' + time2;
    var a = new Date(times1);
    var b = new Date(times2);
    var hours = Math.abs(b - a) / 36e5;
    if (hours > 1) {
      let counts = parseFloat(hours.toFixed(1));
      return counts + ' Hour';
    } else {
      return hours + ' Hour';
    }
  },
  addDays(date, days) {
    var result = new Date(date);
    let count = result.setDate(parseInt(result.getDate()) + parseInt(days));
    return count;
  },
  addMonths(date, month) {
    var result = new Date(date);
    let count = result.setMonth(parseInt(result.getMonth()) + parseInt(month));
    return count;
  },
  formatDateCal(date) {
    moment.locale('id');
    let final = new Date(date);
    let today = moment(final).format('DD/MM/YYYY');
    return today;
  },
  minusDays(date, days) {
    var result = new Date(date);
    let count = result.setDate(result.getDate() - parseInt(days));
    return count;
  },
  formatThousand(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return (n / 1e3).toFixed(0) + "k";
    if (n >= 1e6 && n < 1e9) return (n / 1e6).toFixed(0) + "m";
    if (n >= 1e9 && n < 1e12) return (n / 1e9).toFixed(0) + "t";
    if (n >= 1e12) return (n / 1e12).toFixed() + "t";
  },
  htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  },
  formatDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
      // Special case for 11th, 12th, and 13th
      return day + 'th';
    } else {
      // Get the last digit of the day
      var lastDigit = day % 10;
      
      // Determine the suffix based on the last digit
      var suffix;
      switch (lastDigit) {
        case 1:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
        default:
          suffix = 'th';
      }
  
      return day + suffix;
    }
  },
  dateFormat(date) {
    let dates = new Date(date);
    var days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    var months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    var d = new Date(dates);
    let format = days[d.getDay()] + ', ' + dates.getDate() + ' ' + months[d.getMonth()] + ' ' + dates.getFullYear();
    return format;
  },
  dateFormatNoDays(date) {
    let dates = new Date(date);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    var d = new Date(dates);
    let format = this.formatDayWithSuffix(dates.getDate()) + ' ' + months[d.getMonth()] + ' ' + dates.getFullYear();
    return format;
  },
  hourFormat(tm) {
    moment.locale('id');
    let final = new Date();
    let today = moment(final).format('YYYY-MM-DD') + ' ' + tm;
    let time = moment(today).format('LT');
    return time;
  },
  dateTimeFormat(date) {
    moment.locale('id');
    let dates = new Date(date);
    let format = moment(dates).format('LLL');
    return format;
  },
  findDays(from, to) {
    var d = new Date(from),
      a = [],
      y = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    while (d < to) {
      a.push(y[d.getDay()]);
      d.setDate(d.getDate() + 1);
    }
    if (d.getDay() === to.getDay()) // include last day
      a.push(y[d.getDay()]);
    return a;
  },
  setToSecureStorage(key, object) {
    return new Promise((resolve, reject) => {
      reactLocalStorage.set(key, object).then((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });

    })
  },
  getFromSecureStorage(key) {
    return new Promise((resolve, reject) => {
      reactLocalStorage.get(key)
        .then((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });

    })
  },
  openWhatsappApi(telp) {
    var char = telp[0];
    const replaced = telp.replace(char, '62');
    let link = "https://api.whatsapp.com/send?phone=" + replaced;
    return link;
  },
  removeFromSecureStorage(key) {
    return new Promise((resolve, reject) => {
      reactLocalStorage.remove(key)
        .then((res) => {
          // console.log("RNSecureKeyStore get success : ", res);
          resolve(res);
        }, (err) => {
          // console.log("RNSecureKeyStore get err : ", err);
          reject(err);
        });
    })
  },

  decodeJwtResponse(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  },
  getDuration(seconds) {
    var epoch, interval;

    for (var i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
      epoch = DURATION_IN_SECONDS.epochs[i];
      interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
      if (interval >= 1) {
        return {
          interval: interval,
          epoch: epoch
        };
      }
    }

  },
  timeSince(date) {
    var seconds = Math.floor((new Date() - new Date(date)) / 1000);
    var duration = this.getDuration(seconds);
    var suffix = 0;
    if (duration) {
      suffix = (duration.interval > 1 || duration.interval === 0) ? 's' : '';
      return duration.interval + ' ' + duration.epoch + suffix + ' ago';
    } else {
      return 'Just now';
    }
  },
  convertBase64(file) {
    return new Promise((resolve, reject) => {
      var fileInput = false;
      if (file) {
        fileInput = true;
      }
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            file, 500, 500, "JPEG", 100, 0,
            (uri) => {
              resolve(uri);
            }, "base64", 300, 300
          );
        } catch (err) {
          reject(err);
        }
      }
    })
  },

  convertBase64_2(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  },
  sumFromArray(propertyName, array) {
    let sum = 0;
    array.forEach(item => {
      sum += item[propertyName] ?? 0;
    });
    return sum;
  },
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  },
  removeDuplicates(array, property) {
    const uniqueMap = new Map();
    return array.filter(obj => !uniqueMap.has(obj[property]) && uniqueMap.set(obj[property], 1));
  },
  convertIntToTimeString(timestamp, type) {
    // Create a new Date object with the timestamp (in milliseconds)
    var date = new Date(timestamp * 1000);

    // Extract the individual components of the date
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    var day = ('0' + date.getDate()).slice(-2);
    var hours = ('0' + date.getHours()).slice(-2);
    var minutes = ('0' + date.getMinutes()).slice(-2);
    // Format the date string
    let formattedDate = '';
    if(type === 'date'){
      var dates = `${year}-${month}-${day}`;
      formattedDate = this.dateFormatNoDays(dates);
    }else if(type === 'time'){
      formattedDate = `${hours}:${minutes}`;
    }else{
      formattedDate = `${year}/${month}/${day} ${hours}:${minutes}`;
    }

    return formattedDate;
  },
  listPeriod() {
    let period = [
      {
        label: "This Week",
        value: "7"
      },
      {
        label: "This Month",
        value: "30"
      },
      {
        label: "Last 3 Months",
        value: "90"
      },
      {
        label: "Last 6 Months",
        value: "180"
      }
    ];
    return period;
  },
  listCountry() {
    let country = [
      { label: 'All Country', value: '' },
      { label: 'Afghanistan', value: 'AF' },
      { label: 'Åland Islands', value: 'AX' },
      { label: 'Albania', value: 'AL' },
      { label: 'Algeria', value: 'DZ' },
      { label: 'American Samoa', value: 'AS' },
      { label: 'AndorrA', value: 'AD' },
      { label: 'Angola', value: 'AO' },
      { label: 'Anguilla', value: 'AI' },
      { label: 'Antarctica', value: 'AQ' },
      { label: 'Antigua and Barbuda', value: 'AG' },
      { label: 'Argentina', value: 'AR' },
      { label: 'Armenia', value: 'AM' },
      { label: 'Aruba', value: 'AW' },
      { label: 'Australia', value: 'AU' },
      { label: 'Austria', value: 'AT' },
      { label: 'Azerbaijan', value: 'AZ' },
      { label: 'Bahamas', value: 'BS' },
      { label: 'Bahrain', value: 'BH' },
      { label: 'Bangladesh', value: 'BD' },
      { label: 'Barbados', value: 'BB' },
      { label: 'Belarus', value: 'BY' },
      { label: 'Belgium', value: 'BE' },
      { label: 'Belize', value: 'BZ' },
      { label: 'Benin', value: 'BJ' },
      { label: 'Bermuda', value: 'BM' },
      { label: 'Bhutan', value: 'BT' },
      { label: 'Bolivia', value: 'BO' },
      { label: 'Bosnia and Herzegovina', value: 'BA' },
      { label: 'Botswana', value: 'BW' },
      { label: 'Bouvet Island', value: 'BV' },
      { label: 'Brazil', value: 'BR' },
      { label: 'British Indian Ocean Territory', value: 'IO' },
      { label: 'Brunei Darussalam', value: 'BN' },
      { label: 'Bulgaria', value: 'BG' },
      { label: 'Burkina Faso', value: 'BF' },
      { label: 'Burundi', value: 'BI' },
      { label: 'Cambodia', value: 'KH' },
      { label: 'Cameroon', value: 'CM' },
      { label: 'Canada', value: 'CA' },
      { label: 'Cape Verde', value: 'CV' },
      { label: 'Cayman Islands', value: 'KY' },
      { label: 'Central African Republic', value: 'CF' },
      { label: 'Chad', value: 'TD' },
      { label: 'Chile', value: 'CL' },
      { label: 'China', value: 'CN' },
      { label: 'Christmas Island', value: 'CX' },
      { label: 'Cocos (Keeling) Islands', value: 'CC' },
      { label: 'Colombia', value: 'CO' },
      { label: 'Comoros', value: 'KM' },
      { label: 'Congo', value: 'CG' },
      { label: 'Congo, The Democratic Republic of the', value: 'CD' },
      { label: 'Cook Islands', value: 'CK' },
      { label: 'Costa Rica', value: 'CR' },
      { label: 'Cote D\'Ivoire', value: 'CI' },
      { label: 'Croatia', value: 'HR' },
      { label: 'Cuba', value: 'CU' },
      { label: 'Cyprus', value: 'CY' },
      { label: 'Czech Republic', value: 'CZ' },
      { label: 'Denmark', value: 'DK' },
      { label: 'Djibouti', value: 'DJ' },
      { label: 'Dominica', value: 'DM' },
      { label: 'Dominican Republic', value: 'DO' },
      { label: 'Ecuador', value: 'EC' },
      { label: 'Egypt', value: 'EG' },
      { label: 'El Salvador', value: 'SV' },
      { label: 'Equatorial Guinea', value: 'GQ' },
      { label: 'Eritrea', value: 'ER' },
      { label: 'Estonia', value: 'EE' },
      { label: 'Ethiopia', value: 'ET' },
      { label: 'Falkland Islands (Malvinas)', value: 'FK' },
      { label: 'Faroe Islands', value: 'FO' },
      { label: 'Fiji', value: 'FJ' },
      { label: 'Finland', value: 'FI' },
      { label: 'France', value: 'FR' },
      { label: 'French Guiana', value: 'GF' },
      { label: 'French Polynesia', value: 'PF' },
      { label: 'French Southern Territories', value: 'TF' },
      { label: 'Gabon', value: 'GA' },
      { label: 'Gambia', value: 'GM' },
      { label: 'Georgia', value: 'GE' },
      { label: 'Germany', value: 'DE' },
      { label: 'Ghana', value: 'GH' },
      { label: 'Gibraltar', value: 'GI' },
      { label: 'Greece', value: 'GR' },
      { label: 'Greenland', value: 'GL' },
      { label: 'Grenada', value: 'GD' },
      { label: 'Guadeloupe', value: 'GP' },
      { label: 'Guam', value: 'GU' },
      { label: 'Guatemala', value: 'GT' },
      { label: 'Guernsey', value: 'GG' },
      { label: 'Guinea', value: 'GN' },
      { label: 'Guinea-Bissau', value: 'GW' },
      { label: 'Guyana', value: 'GY' },
      { label: 'Haiti', value: 'HT' },
      { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
      { label: 'Holy See (Vatican City State)', value: 'VA' },
      { label: 'Honduras', value: 'HN' },
      { label: 'Hong Kong', value: 'HK' },
      { label: 'Hungary', value: 'HU' },
      { label: 'Iceland', value: 'IS' },
      { label: 'India', value: 'IN' },
      { label: 'Indonesia', value: 'ID' },
      { label: 'Iran, Islamic Republic Of', value: 'IR' },
      { label: 'Iraq', value: 'IQ' },
      { label: 'Ireland', value: 'IE' },
      { label: 'Isle of Man', value: 'IM' },
      { label: 'Israel', value: 'IL' },
      { label: 'Italy', value: 'IT' },
      { label: 'Jamaica', value: 'JM' },
      { label: 'Japan', value: 'JP' },
      { label: 'Jersey', value: 'JE' },
      { label: 'Jordan', value: 'JO' },
      { label: 'Kazakhstan', value: 'KZ' },
      { label: 'Kenya', value: 'KE' },
      { label: 'Kiribati', value: 'KI' },
      { label: 'Korea, Democratic People\'S Republic of', value: 'KP' },
      { label: 'Korea, Republic of', value: 'KR' },
      { label: 'Kuwait', value: 'KW' },
      { label: 'Kyrgyzstan', value: 'KG' },
      { label: 'Lao People\'S Democratic Republic', value: 'LA' },
      { label: 'Latvia', value: 'LV' },
      { label: 'Lebanon', value: 'LB' },
      { label: 'Lesotho', value: 'LS' },
      { label: 'Liberia', value: 'LR' },
      { label: 'Libyan Arab Jamahiriya', value: 'LY' },
      { label: 'Liechtenstein', value: 'LI' },
      { label: 'Lithuania', value: 'LT' },
      { label: 'Luxembourg', value: 'LU' },
      { label: 'Macao', value: 'MO' },
      { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
      { label: 'Madagascar', value: 'MG' },
      { label: 'Malawi', value: 'MW' },
      { label: 'Malaysia', value: 'MY' },
      { label: 'Maldives', value: 'MV' },
      { label: 'Mali', value: 'ML' },
      { label: 'Malta', value: 'MT' },
      { label: 'Marshall Islands', value: 'MH' },
      { label: 'Martinique', value: 'MQ' },
      { label: 'Mauritania', value: 'MR' },
      { label: 'Mauritius', value: 'MU' },
      { label: 'Mayotte', value: 'YT' },
      { label: 'Mexico', value: 'MX' },
      { label: 'Micronesia, Federated States of', value: 'FM' },
      { label: 'Moldova, Republic of', value: 'MD' },
      { label: 'Monaco', value: 'MC' },
      { label: 'Mongolia', value: 'MN' },
      { label: 'Montserrat', value: 'MS' },
      { label: 'Morocco', value: 'MA' },
      { label: 'Mozambique', value: 'MZ' },
      { label: 'Myanmar', value: 'MM' },
      { label: 'Namibia', value: 'NA' },
      { label: 'Nauru', value: 'NR' },
      { label: 'Nepal', value: 'NP' },
      { label: 'Netherlands', value: 'NL' },
      { label: 'Netherlands Antilles', value: 'AN' },
      { label: 'New Caledonia', value: 'NC' },
      { label: 'New Zealand', value: 'NZ' },
      { label: 'Nicaragua', value: 'NI' },
      { label: 'Niger', value: 'NE' },
      { label: 'Nigeria', value: 'NG' },
      { label: 'Niue', value: 'NU' },
      { label: 'Norfolk Island', value: 'NF' },
      { label: 'Northern Mariana Islands', value: 'MP' },
      { label: 'Norway', value: 'NO' },
      { label: 'Oman', value: 'OM' },
      { label: 'Pakistan', value: 'PK' },
      { label: 'Palau', value: 'PW' },
      { label: 'Palestinian Territory, Occupied', value: 'PS' },
      { label: 'Panama', value: 'PA' },
      { label: 'Papua New Guinea', value: 'PG' },
      { label: 'Paraguay', value: 'PY' },
      { label: 'Peru', value: 'PE' },
      { label: 'Philippines', value: 'PH' },
      { label: 'Pitcairn', value: 'PN' },
      { label: 'Poland', value: 'PL' },
      { label: 'Portugal', value: 'PT' },
      { label: 'Puerto Rico', value: 'PR' },
      { label: 'Qatar', value: 'QA' },
      { label: 'Reunion', value: 'RE' },
      { label: 'Romania', value: 'RO' },
      { label: 'Russian Federation', value: 'RU' },
      { label: 'RWANDA', value: 'RW' },
      { label: 'Saint Helena', value: 'SH' },
      { label: 'Saint Kitts and Nevis', value: 'KN' },
      { label: 'Saint Lucia', value: 'LC' },
      { label: 'Saint Pierre and Miquelon', value: 'PM' },
      { label: 'Saint Vincent and the Grenadines', value: 'VC' },
      { label: 'Samoa', value: 'WS' },
      { label: 'San Marino', value: 'SM' },
      { label: 'Sao Tome and Principe', value: 'ST' },
      { label: 'Saudi Arabia', value: 'SA' },
      { label: 'Senegal', value: 'SN' },
      { label: 'Serbia and Montenegro', value: 'CS' },
      { label: 'Seychelles', value: 'SC' },
      { label: 'Sierra Leone', value: 'SL' },
      { label: 'Singapore', value: 'SG' },
      { label: 'Slovakia', value: 'SK' },
      { label: 'Slovenia', value: 'SI' },
      { label: 'Solomon Islands', value: 'SB' },
      { label: 'Somalia', value: 'SO' },
      { label: 'South Africa', value: 'ZA' },
      { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
      { label: 'Spain', value: 'ES' },
      { label: 'Sri Lanka', value: 'LK' },
      { label: 'Sudan', value: 'SD' },
      { label: 'Surilabel', value: 'SR' },
      { label: 'Svalbard and Jan Mayen', value: 'SJ' },
      { label: 'Swaziland', value: 'SZ' },
      { label: 'Sweden', value: 'SE' },
      { label: 'Switzerland', value: 'CH' },
      { label: 'Syrian Arab Republic', value: 'SY' },
      { label: 'Taiwan, Province of China', value: 'TW' },
      { label: 'Tajikistan', value: 'TJ' },
      { label: 'Tanzania, United Republic of', value: 'TZ' },
      { label: 'Thailand', value: 'TH' },
      { label: 'Timor-Leste', value: 'TL' },
      { label: 'Togo', value: 'TG' },
      { label: 'Tokelau', value: 'TK' },
      { label: 'Tonga', value: 'TO' },
      { label: 'Trinidad and Tobago', value: 'TT' },
      { label: 'Tunisia', value: 'TN' },
      { label: 'Turkey', value: 'TR' },
      { label: 'Turkmenistan', value: 'TM' },
      { label: 'Turks and Caicos Islands', value: 'TC' },
      { label: 'Tuvalu', value: 'TV' },
      { label: 'Uganda', value: 'UG' },
      { label: 'Ukraine', value: 'UA' },
      { label: 'United Arab Emirates', value: 'AE' },
      { label: 'United Kingdom', value: 'GB' },
      { label: 'United States', value: 'US' },
      { label: 'United States Minor Outlying Islands', value: 'UM' },
      { label: 'Uruguay', value: 'UY' },
      { label: 'Uzbekistan', value: 'UZ' },
      { label: 'Vanuatu', value: 'VU' },
      { label: 'Venezuela', value: 'VE' },
      { label: 'Viet Nam', value: 'VN' },
      { label: 'Virgin Islands, British', value: 'VG' },
      { label: 'Virgin Islands, U.S.', value: 'VI' },
      { label: 'Wallis and Futuna', value: 'WF' },
      { label: 'Western Sahara', value: 'EH' },
      { label: 'Yemen', value: 'YE' },
      { label: 'Zambia', value: 'ZM' },
      { label: 'Zimbabwe', value: 'ZW' }
    ];
    return country;
  },
  listCountryWithoutAll() {
    let country = [
      { label: 'Afghanistan', value: 'AF' },
      { label: 'Åland Islands', value: 'AX' },
      { label: 'Albania', value: 'AL' },
      { label: 'Algeria', value: 'DZ' },
      { label: 'American Samoa', value: 'AS' },
      { label: 'AndorrA', value: 'AD' },
      { label: 'Angola', value: 'AO' },
      { label: 'Anguilla', value: 'AI' },
      { label: 'Antarctica', value: 'AQ' },
      { label: 'Antigua and Barbuda', value: 'AG' },
      { label: 'Argentina', value: 'AR' },
      { label: 'Armenia', value: 'AM' },
      { label: 'Aruba', value: 'AW' },
      { label: 'Australia', value: 'AU' },
      { label: 'Austria', value: 'AT' },
      { label: 'Azerbaijan', value: 'AZ' },
      { label: 'Bahamas', value: 'BS' },
      { label: 'Bahrain', value: 'BH' },
      { label: 'Bangladesh', value: 'BD' },
      { label: 'Barbados', value: 'BB' },
      { label: 'Belarus', value: 'BY' },
      { label: 'Belgium', value: 'BE' },
      { label: 'Belize', value: 'BZ' },
      { label: 'Benin', value: 'BJ' },
      { label: 'Bermuda', value: 'BM' },
      { label: 'Bhutan', value: 'BT' },
      { label: 'Bolivia', value: 'BO' },
      { label: 'Bosnia and Herzegovina', value: 'BA' },
      { label: 'Botswana', value: 'BW' },
      { label: 'Bouvet Island', value: 'BV' },
      { label: 'Brazil', value: 'BR' },
      { label: 'British Indian Ocean Territory', value: 'IO' },
      { label: 'Brunei Darussalam', value: 'BN' },
      { label: 'Bulgaria', value: 'BG' },
      { label: 'Burkina Faso', value: 'BF' },
      { label: 'Burundi', value: 'BI' },
      { label: 'Cambodia', value: 'KH' },
      { label: 'Cameroon', value: 'CM' },
      { label: 'Canada', value: 'CA' },
      { label: 'Cape Verde', value: 'CV' },
      { label: 'Cayman Islands', value: 'KY' },
      { label: 'Central African Republic', value: 'CF' },
      { label: 'Chad', value: 'TD' },
      { label: 'Chile', value: 'CL' },
      { label: 'China', value: 'CN' },
      { label: 'Christmas Island', value: 'CX' },
      { label: 'Cocos (Keeling) Islands', value: 'CC' },
      { label: 'Colombia', value: 'CO' },
      { label: 'Comoros', value: 'KM' },
      { label: 'Congo', value: 'CG' },
      { label: 'Congo, The Democratic Republic of the', value: 'CD' },
      { label: 'Cook Islands', value: 'CK' },
      { label: 'Costa Rica', value: 'CR' },
      { label: 'Cote D\'Ivoire', value: 'CI' },
      { label: 'Croatia', value: 'HR' },
      { label: 'Cuba', value: 'CU' },
      { label: 'Cyprus', value: 'CY' },
      { label: 'Czech Republic', value: 'CZ' },
      { label: 'Denmark', value: 'DK' },
      { label: 'Djibouti', value: 'DJ' },
      { label: 'Dominica', value: 'DM' },
      { label: 'Dominican Republic', value: 'DO' },
      { label: 'Ecuador', value: 'EC' },
      { label: 'Egypt', value: 'EG' },
      { label: 'El Salvador', value: 'SV' },
      { label: 'Equatorial Guinea', value: 'GQ' },
      { label: 'Eritrea', value: 'ER' },
      { label: 'Estonia', value: 'EE' },
      { label: 'Ethiopia', value: 'ET' },
      { label: 'Falkland Islands (Malvinas)', value: 'FK' },
      { label: 'Faroe Islands', value: 'FO' },
      { label: 'Fiji', value: 'FJ' },
      { label: 'Finland', value: 'FI' },
      { label: 'France', value: 'FR' },
      { label: 'French Guiana', value: 'GF' },
      { label: 'French Polynesia', value: 'PF' },
      { label: 'French Southern Territories', value: 'TF' },
      { label: 'Gabon', value: 'GA' },
      { label: 'Gambia', value: 'GM' },
      { label: 'Georgia', value: 'GE' },
      { label: 'Germany', value: 'DE' },
      { label: 'Ghana', value: 'GH' },
      { label: 'Gibraltar', value: 'GI' },
      { label: 'Greece', value: 'GR' },
      { label: 'Greenland', value: 'GL' },
      { label: 'Grenada', value: 'GD' },
      { label: 'Guadeloupe', value: 'GP' },
      { label: 'Guam', value: 'GU' },
      { label: 'Guatemala', value: 'GT' },
      { label: 'Guernsey', value: 'GG' },
      { label: 'Guinea', value: 'GN' },
      { label: 'Guinea-Bissau', value: 'GW' },
      { label: 'Guyana', value: 'GY' },
      { label: 'Haiti', value: 'HT' },
      { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
      { label: 'Holy See (Vatican City State)', value: 'VA' },
      { label: 'Honduras', value: 'HN' },
      { label: 'Hong Kong', value: 'HK' },
      { label: 'Hungary', value: 'HU' },
      { label: 'Iceland', value: 'IS' },
      { label: 'India', value: 'IN' },
      { label: 'Indonesia', value: 'ID' },
      { label: 'Iran, Islamic Republic Of', value: 'IR' },
      { label: 'Iraq', value: 'IQ' },
      { label: 'Ireland', value: 'IE' },
      { label: 'Isle of Man', value: 'IM' },
      { label: 'Israel', value: 'IL' },
      { label: 'Italy', value: 'IT' },
      { label: 'Jamaica', value: 'JM' },
      { label: 'Japan', value: 'JP' },
      { label: 'Jersey', value: 'JE' },
      { label: 'Jordan', value: 'JO' },
      { label: 'Kazakhstan', value: 'KZ' },
      { label: 'Kenya', value: 'KE' },
      { label: 'Kiribati', value: 'KI' },
      { label: 'Korea, Democratic People\'S Republic of', value: 'KP' },
      { label: 'Korea, Republic of', value: 'KR' },
      { label: 'Kuwait', value: 'KW' },
      { label: 'Kyrgyzstan', value: 'KG' },
      { label: 'Lao People\'S Democratic Republic', value: 'LA' },
      { label: 'Latvia', value: 'LV' },
      { label: 'Lebanon', value: 'LB' },
      { label: 'Lesotho', value: 'LS' },
      { label: 'Liberia', value: 'LR' },
      { label: 'Libyan Arab Jamahiriya', value: 'LY' },
      { label: 'Liechtenstein', value: 'LI' },
      { label: 'Lithuania', value: 'LT' },
      { label: 'Luxembourg', value: 'LU' },
      { label: 'Macao', value: 'MO' },
      { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
      { label: 'Madagascar', value: 'MG' },
      { label: 'Malawi', value: 'MW' },
      { label: 'Malaysia', value: 'MY' },
      { label: 'Maldives', value: 'MV' },
      { label: 'Mali', value: 'ML' },
      { label: 'Malta', value: 'MT' },
      { label: 'Marshall Islands', value: 'MH' },
      { label: 'Martinique', value: 'MQ' },
      { label: 'Mauritania', value: 'MR' },
      { label: 'Mauritius', value: 'MU' },
      { label: 'Mayotte', value: 'YT' },
      { label: 'Mexico', value: 'MX' },
      { label: 'Micronesia, Federated States of', value: 'FM' },
      { label: 'Moldova, Republic of', value: 'MD' },
      { label: 'Monaco', value: 'MC' },
      { label: 'Mongolia', value: 'MN' },
      { label: 'Montserrat', value: 'MS' },
      { label: 'Morocco', value: 'MA' },
      { label: 'Mozambique', value: 'MZ' },
      { label: 'Myanmar', value: 'MM' },
      { label: 'Namibia', value: 'NA' },
      { label: 'Nauru', value: 'NR' },
      { label: 'Nepal', value: 'NP' },
      { label: 'Netherlands', value: 'NL' },
      { label: 'Netherlands Antilles', value: 'AN' },
      { label: 'New Caledonia', value: 'NC' },
      { label: 'New Zealand', value: 'NZ' },
      { label: 'Nicaragua', value: 'NI' },
      { label: 'Niger', value: 'NE' },
      { label: 'Nigeria', value: 'NG' },
      { label: 'Niue', value: 'NU' },
      { label: 'Norfolk Island', value: 'NF' },
      { label: 'Northern Mariana Islands', value: 'MP' },
      { label: 'Norway', value: 'NO' },
      { label: 'Oman', value: 'OM' },
      { label: 'Pakistan', value: 'PK' },
      { label: 'Palau', value: 'PW' },
      { label: 'Palestinian Territory, Occupied', value: 'PS' },
      { label: 'Panama', value: 'PA' },
      { label: 'Papua New Guinea', value: 'PG' },
      { label: 'Paraguay', value: 'PY' },
      { label: 'Peru', value: 'PE' },
      { label: 'Philippines', value: 'PH' },
      { label: 'Pitcairn', value: 'PN' },
      { label: 'Poland', value: 'PL' },
      { label: 'Portugal', value: 'PT' },
      { label: 'Puerto Rico', value: 'PR' },
      { label: 'Qatar', value: 'QA' },
      { label: 'Reunion', value: 'RE' },
      { label: 'Romania', value: 'RO' },
      { label: 'Russian Federation', value: 'RU' },
      { label: 'RWANDA', value: 'RW' },
      { label: 'Saint Helena', value: 'SH' },
      { label: 'Saint Kitts and Nevis', value: 'KN' },
      { label: 'Saint Lucia', value: 'LC' },
      { label: 'Saint Pierre and Miquelon', value: 'PM' },
      { label: 'Saint Vincent and the Grenadines', value: 'VC' },
      { label: 'Samoa', value: 'WS' },
      { label: 'San Marino', value: 'SM' },
      { label: 'Sao Tome and Principe', value: 'ST' },
      { label: 'Saudi Arabia', value: 'SA' },
      { label: 'Senegal', value: 'SN' },
      { label: 'Serbia and Montenegro', value: 'CS' },
      { label: 'Seychelles', value: 'SC' },
      { label: 'Sierra Leone', value: 'SL' },
      { label: 'Singapore', value: 'SG' },
      { label: 'Slovakia', value: 'SK' },
      { label: 'Slovenia', value: 'SI' },
      { label: 'Solomon Islands', value: 'SB' },
      { label: 'Somalia', value: 'SO' },
      { label: 'South Africa', value: 'ZA' },
      { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
      { label: 'Spain', value: 'ES' },
      { label: 'Sri Lanka', value: 'LK' },
      { label: 'Sudan', value: 'SD' },
      { label: 'Surilabel', value: 'SR' },
      { label: 'Svalbard and Jan Mayen', value: 'SJ' },
      { label: 'Swaziland', value: 'SZ' },
      { label: 'Sweden', value: 'SE' },
      { label: 'Switzerland', value: 'CH' },
      { label: 'Syrian Arab Republic', value: 'SY' },
      { label: 'Taiwan, Province of China', value: 'TW' },
      { label: 'Tajikistan', value: 'TJ' },
      { label: 'Tanzania, United Republic of', value: 'TZ' },
      { label: 'Thailand', value: 'TH' },
      { label: 'Timor-Leste', value: 'TL' },
      { label: 'Togo', value: 'TG' },
      { label: 'Tokelau', value: 'TK' },
      { label: 'Tonga', value: 'TO' },
      { label: 'Trinidad and Tobago', value: 'TT' },
      { label: 'Tunisia', value: 'TN' },
      { label: 'Turkey', value: 'TR' },
      { label: 'Turkmenistan', value: 'TM' },
      { label: 'Turks and Caicos Islands', value: 'TC' },
      { label: 'Tuvalu', value: 'TV' },
      { label: 'Uganda', value: 'UG' },
      { label: 'Ukraine', value: 'UA' },
      { label: 'United Arab Emirates', value: 'AE' },
      { label: 'United Kingdom', value: 'GB' },
      { label: 'United States', value: 'US' },
      { label: 'United States Minor Outlying Islands', value: 'UM' },
      { label: 'Uruguay', value: 'UY' },
      { label: 'Uzbekistan', value: 'UZ' },
      { label: 'Vanuatu', value: 'VU' },
      { label: 'Venezuela', value: 'VE' },
      { label: 'Viet Nam', value: 'VN' },
      { label: 'Virgin Islands, British', value: 'VG' },
      { label: 'Virgin Islands, U.S.', value: 'VI' },
      { label: 'Wallis and Futuna', value: 'WF' },
      { label: 'Western Sahara', value: 'EH' },
      { label: 'Yemen', value: 'YE' },
      { label: 'Zambia', value: 'ZM' },
      { label: 'Zimbabwe', value: 'ZW' }
    ];
    return country;
  },
  listLanguage() {
    const languageCodes = [
      "en-IE", "ar-AE", "pa-IN", "be-BY", "te-IN", "zh-TW", "en-US", "uk-UA", "sw-KE", "gu-IN",
      "ta-IN", "en-AB", "ug-CN", "su-ID", "bn-IN", "hy-AM", "en-IN", "sl-SI", "ab-GE", "zh-CN",
      "ar-SA", "eu-ES", "en-ZA", "gd-GB", "cy-WL", "uz-UZ", "tl-PH", "so-SO", "sk-SK", "rw-RW",
      "ro-RO", "pl-PL", "no-NO", "mt-MT", "mr-IN", "mn-MN", "mk-MK", "lv-LV", "lt-LT", "is-IS",
      "hu-HU", "hr-HR", "ha-NG", "fi-FI", "et-ET", "bg-BG", "az-AZ", "th-TH", "tr-TR", "ru-RU",
      "pt-PT", "nl-NL", "it-IT", "id-ID", "fr-FR", "es-ES", "de-DE", "sw-RW", "sw-TZ", "sr-RS",
      "ps-AF", "or-IN", "kn-IN", "ga-IE", "af-ZA", "wo-SN", "tt-RU", "sw-BI", "en-NZ", "ko-KR",
      "el-GR", "ba-RU", "hi-IN", "de-CH", "vi-VN", "cy-GB", "ml-IN", "ms-MY", "he-IL", "cs-CZ",
      "ka-GE", "si-LK", "gl-ES", "lg-IN", "kab-DZ", "da-DK", "en-AU", "zu-ZA", "mhr-RU", "ast-ES",
      "pt-BR", "en-WL", "sw-UG", "ky-KG", "ckb-IQ", "bs-BA", "fa-IR", "kk-KZ", "ckb-IR", "sv-SE",
      "ja-JP", "mi-NZ", "ca-ES", "es-US", "fr-CA", "en-GB"
    ];

    // Object mapping language codes to language names
    const languageNames = {
      "en-IE": "English (Ireland)",
      "ar-AE": "Arabic (United Arab Emirates)",
      "pa-IN": "Punjabi (India)",
      "be-BY": "Belarusian (Belarus)",
      "te-IN": "Telugu (India)",
      "zh-TW": "Chinese (Taiwan)",
      "en-US": "English (United States)",
      "uk-UA": "Ukrainian (Ukraine)",
      "sw-KE": "Swahili (Kenya)",
      "gu-IN": "Gujarati (India)",
      "ta-IN": "Tamil (India)",
      "en-AB": "English (Abkhazia)",
      "ug-CN": "Uyghur (China)",
      "su-ID": "Sundanese (Indonesia)",
      "bn-IN": "Bengali (India)",
      "hy-AM": "Armenian (Armenia)",
      "en-IN": "English (India)",
      "sl-SI": "Slovenian (Slovenia)",
      "ab-GE": "Abkhaz (Georgia)",
      "zh-CN": "Chinese (China)",
      "ar-SA": "Arabic (Saudi Arabia)",
      "eu-ES": "Basque (Spain)",
      "en-ZA": "English (South Africa)",
      "gd-GB": "Scottish Gaelic (United Kingdom)",
      "cy-WL": "Welsh (Wales)",
      "uz-UZ": "Uzbek (Uzbekistan)",
      "tl-PH": "Tagalog (Philippines)",
      "so-SO": "Somali (Somalia)",
      "sk-SK": "Slovak (Slovakia)",
      "rw-RW": "Kinyarwanda (Rwanda)",
      "ro-RO": "Romanian (Romania)",
      "pl-PL": "Polish (Poland)",
      "no-NO": "Norwegian (Norway)",
      "mt-MT": "Maltese (Malta)",
      "mr-IN": "Marathi (India)",
      "mn-MN": "Mongolian (Mongolia)",
      "mk-MK": "Macedonian (North Macedonia)",
      "lv-LV": "Latvian (Latvia)",
      "lt-LT": "Lithuanian (Lithuania)",
      "is-IS": "Icelandic (Iceland)",
      "hu-HU": "Hungarian (Hungary)",
      "hr-HR": "Croatian (Croatia)",
      "ha-NG": "Hausa (Nigeria)",
      "fi-FI": "Finnish (Finland)",
      "et-ET": "Estonian (Estonia)",
      "bg-BG": "Bulgarian (Bulgaria)",
      "az-AZ": "Azerbaijani (Azerbaijan)",
      "th-TH": "Thai (Thailand)",
      "tr-TR": "Turkish (Turkey)",
      "ru-RU": "Russian (Russia)",
      "pt-PT": "Portuguese (Portugal)",
      "nl-NL": "Dutch (Netherlands)",
      "it-IT": "Italian (Italy)",
      "id-ID": "Indonesian (Indonesia)",
      "fr-FR": "French (France)",
      "es-ES": "Spanish (Spain)",
      "de-DE": "German (Germany)",
      "sw-RW": "Swahili (Rwanda)",
      "sw-TZ": "Swahili (Tanzania)",
      "sr-RS": "Serbian (Serbia)",
      "ps-AF": "Pashto (Afghanistan)",
      "or-IN": "Odia (India)",
      "kn-IN": "Kannada (India)",
      "ga-IE": "Irish (Ireland)",
      "af-ZA": "Afrikaans (South Africa)",
      "wo-SN": "Wolof (Senegal)",
      "tt-RU": "Tatar (Russia)",
      "sw-BI": "Swahili (Burundi)",
      "en-NZ": "English (New Zealand)",
      "ko-KR": "Korean (South Korea)",
      "el-GR": "Greek (Greece)",
      "ba-RU": "Bashkir (Russia)",
      "hi-IN": "Hindi (India)",
      "de-CH": "German (Switzerland)",
      "vi-VN": "Vietnamese (Vietnam)",
      "cy-GB": "Welsh (United Kingdom)",
      "ml-IN": "Malayalam (India)",
      "ms-MY": "Malay (Malaysia)",
      "he-IL": "Hebrew (Israel)",
      "cs-CZ": "Czech (Czech Republic)",
      "ka-GE": "Georgian (Georgia)",
      "si-LK": "Sinhala (Sri Lanka)",
      "gl-ES": "Galician (Spain)",
      "lg-IN": "Ganda (India)",
      "kab-DZ": "Kabyle (Algeria)",
      "da-DK": "Danish (Denmark)",
      "en-AU": "English (Australia)",
      "zu-ZA": "Zulu (South Africa)",
      "mhr-RU": "Mari (Russia)",
      "ast-ES": "Asturian (Spain)",
      "pt-BR": "Portuguese (Brazil)",
      "en-WL": "English (Wales)",
      "sw-UG": "Swahili (Uganda)",
      "ky-KG": "Kyrgyz (Kyrgyzstan)",
      "ckb-IQ": "Central Kurdish (Iraq)",
      "bs-BA": "Bosnian (Bosnia and Herzegovina)",
      "fa-IR": "Persian (Iran)",
      "kk-KZ": "Kazakh (Kazakhstan)",
      "ckb-IR": "Central Kurdish (Iran)",
      "sv-SE": "Swedish (Sweden)",
      "ja-JP": "Japanese (Japan)",
      "mi-NZ": "Maori (New Zealand)",
      "ca-ES": "Catalan (Spain)",
      "es-US": "Spanish (United States)",
      "fr-CA": "French (Canada)",
      "en-GB": "English (United Kingdom)"
    };

    // Create an array of objects with value and label
    const languageArray = languageCodes.map(code => ({
      value: code,
      label: languageNames[code] || "Unknown Language"
    }));

    // Output the resulting array
    return languageArray;
  }
}

export default common;
